<template>
  <div :class="[`d-grid gap-${gap}`, ...columnClasses]">
    <template v-if="!loading">
      <slot></slot>
      <div class="ads-wrapper"
           :class="['animate__animated', 'animate__bounceInDown', 'animate__delay-3s',`gap-${gap}`]"
           :style="adsGridStyle">
        <cmp-ads v-if="adsCount >= 1" class="animate__animated animate__bounceInDown animate__delay-3s"/>
        <cmp-ads v-if="adsCount >= 2" src="/img/sample-ads/3.png" title="Discount Coupon" class="animate__animated animate__bounceInDown animate__delay-4s"/>
      </div>
    </template>
    <div v-else class="loading-indicator">
      Loading...
    </div>
  </div>
</template>

<script>
import CmpAds from "./cmpAds.vue";

export default {
  name: "ListWithAds",
  components: { CmpAds },
  props: {
    loading: {
      type: Boolean,
      default: true
    },
    adsCount: {
      type: Number,
      default: 2
    },
    columns: {
      type: Object,
      default: () => ({})
    },
    gap: {
      type: Number,
      default: 20
    }
  },
  computed: {
    columnClasses() {
      const defaultColumns = {
        xs: 1,
        sm: 1,
        md: 2,
        lg: 3,
        xl: 3,
        xxl: 4
      };

      const mergedColumns = {...defaultColumns, ...this.columns};

      return Object.entries(mergedColumns).map(([size, count]) => `grid-c-${size}-${count}`);
    },
    adsGridStyle() {
      return {
        gridColumn: '-2', // This places the ads in the last column
        gridRow: '1', // This ensures it's in the first row
      };
    }
  }
}
</script>

<style lang="scss" scoped>
.d-grid {
  display: grid;
}

.ads-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.loading-indicator {
  grid-column: 1 / -1;
  text-align: center;
  padding: 20px;
}
</style>
