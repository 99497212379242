<template>
  <div class="farmListAllItem">
    <CRow class="gap-10" align-horizontal="center">
      <CCol col="12" class="vertical-g-10">
        <summarizeFarmCard class="main-card my-2" v-for="(item, index) of farmsList" :item="item" :key="index"/>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import {apiUrlRoot} from "../../../constants/config";
import SummarizeFarmCard from "../../../components/summarizeFarmCard.vue";

export default {
  props: {
    farmList: {
      type: Array,
    },
  },
  components: {SummarizeFarmCard},
  data() {
    return {
      farmsList: this.farmList,
    };
  },
  computed: {},
  methods: {
    apiUrlRoot() {
      return apiUrlRoot
    }
  },
  mounted() {
  },
  created() {},
};
</script>

<style scoped>
.farmListAllItem {
  min-height: 400px;
  overflow-y: auto;
}
</style>
