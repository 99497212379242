<script>
export default {
  name: "summarizeFarmCard",
    props: {
      item: {
        type: Object
      }
    },
    components: {},
    data() {
      return {

      };
    },
    computed: {},
    methods: {},
    mounted() {},
    created() {}
  };
</script>

<template>
  <div class="main-card vertical-g-10">
    <h4 class="farmName">{{ item.title }}</h4>
    <div class="farmInfo">
      <h4>نوع کشت</h4>
      <h3>{{ item.cultivationsType }}</h3>
      <h4>نوع محصول</h4>
      <h3>{{ item.product }}</h3>
    </div>
    <hr class="w-100"/>
    <h4 class="farmAddress y-center-g-5">
      <font-awesome-icon icon="map"/>
      <span>{{ item.address }}</span>
    </h4>
  </div>
</template>

<style scoped>

.main-card {
  width: 100%;
  border-radius: var(--border-radius);
  box-shadow: 0 0 3px 0 #aaa;
  position: relative;
  background-color: whitesmoke;
  overflow: visible !important;
}
.farmName {
  position: absolute;
  top: -12px;
  right: 2%;
  background-color: #fefefe;
  color: #484747;
  padding: 5px;
  font-size: 15px;
  font-weight: bold;
  border-radius: 50%;
}
.farmInfo {
  display: flex;
  margin-top: 10px;
}
.farmInfo h4 {
  font-size: 15px;
  background-color: var(--secondary);
  padding: 2px 3px;
  border-radius: 0 var(--border-radius) var(--border-radius) 0;
  color: #fff;
  margin-right: 10px;
}
.farmInfo h3 {
  font-size: 15px;
  border: 1px solid var(--secondary);
  padding: 1px 16px;
  border-radius: var(--border-radius) 0 0 var(--border-radius);
  font-weight: bold;
}
.farmAddress {
  font-size: 13px;
  padding: 0;
  color: #646464;
  margin-right: 10px;
}
.farmListAllItem {
  overflow-y: auto;
  max-height: 50vh;
  min-height: 400px;
  padding: 20px 10px;
}

</style>
