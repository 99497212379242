<template>
  <div>
    <vue-element-loading
      :active="loading"
      :text="loadingText"
      spinner="bar-fade-scale"
      color="var(--secondary)"
    />
    <CCallout color="info">
      <strong class="h5">لیست تمام فاکتور های پرداخت شده و نشده</strong>
      <br/>
      <small class="text-muted">با کلیک روی هر فاکتور جزئیات مربوط به فاکتور را مشاهده کنید</small>
    </CCallout>
    <div class="factorList p-2">
      <h4 v-if="emptyTitle" class="emptyTitleStyle">
        هیج فاکتوری ثبت نشده است
      </h4>
      <list-with-ads :loading="loading" v-else>
        <card-action
          v-for="(item, index) in buyServiceList"
          :key="index"
          :title="item.paymentStateNumber === 1 ? 'فاکتور': 'پیش فاکتور'"
          @onCardHeaderAction="onCardHeaderAction(item)"
        >
          <template #header-action-icon>
            <font-awesome-icon icon="check" v-if="item.paymentStateNumber === 1"/>
            <font-awesome-icon icon="trash" v-else/>
          </template>
          <CRow>
            <CCol md="6">
              <div class="factorItemInfo">
                <h4>مبلغ</h4>
              </div>
            </CCol>

            <CCol md="6">

              <div class="factorItemInfo">
                <h5>{{ item.amount.toLocaleString() }} <span class="small">ریال</span></h5>
              </div>
            </CCol>

            <CCol md="6">
              <div class="factorItemInfo">
                <h4>تاریخ</h4>
              </div>
            </CCol>

            <CCol md="6">
              <div class="factorItemInfo">
                <h5 style="direction: ltr">{{ item.createDate }}</h5>
              </div>
            </CCol>
            <CCol md="6">
              <div class="factorItemInfo">
                <h4>روش پرداخت</h4>
              </div>
            </CCol>
            <CCol md="6">
              <div class="factorItemInfo">
                <h5
                  style="font-size: 12px; text-align: right;"
                >
                  {{ item.paymentType }}
                </h5>
              </div>
            </CCol>

            <CCol md="6">
              <div class="factorItemInfo">
                <h4>تعداد زمین</h4>
              </div>
            </CCol>

            <CCol md="6">
              <div class="factorItemInfo">
                <h5>{{ item.farmCount }}</h5>
              </div>
            </CCol>
            <CCol md="6">
              <div class="factorItemInfo">
                <h4>مجموع هکتار</h4>
              </div>
            </CCol>
            <CCol md="6">
              <div class="factorItemInfo">
                <h5>{{ item.area }}</h5>
              </div>
            </CCol>

            <CCol md="6">
              <div class="factorItemInfo">
                <h4>تعداد ماه</h4>
              </div>
            </CCol>
            <CCol md="6">
              <div class="factorItemInfo">
                <h5>{{ item.monthCount }}</h5>
              </div>
            </CCol>
          </CRow>
          <hr class="my-3"/>
          <CRow class="gap-5">
            <CCol class="text-center p-0">
              <CButton size="sm" class="w-100" color="primary" @click="getAllFarmWithBuyService(item.id)">
                جزئیات زمین ها
              </CButton>
            </CCol>
            <CCol class="text-center p-0">
              <CButton size="sm" class="w-100" color="primary" @click="getBuyServiceDetail(item.id)">
                جزئیات فاکتور
              </CButton>
            </CCol>
          </CRow>
        </card-action>
      </list-with-ads>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import CardAction from "../../../components/cardAction.vue";
import ListWithAds from "../../../components/list-with-ads.vue";
import {apiUrlRoot} from "../../../constants/config";

export default {
  components: {ListWithAds, CardAction},
  data() {
    return {
      loading: false,
      loadingText: "",
      buyServiceList: [],
      emptyTitle: false,
    };
  },
  computed: {},
  methods: {
    ...mapActions("financialUser", [
      "ShopReportGetAllBuyService",
      "DeletePrefixInfo",
    ]),
    onCardHeaderAction(item) {
      if (item.paymentStateNumber !== 1) {
        this.deletePerfixInfo(item.id);
      }
    },
    async getAllBuyServiceListInfo() {
      this.buyServiceList = [];
      this.loading = true;
      this.loadingText = "دریافت اطلاعات خرید";
      let result = await this.ShopReportGetAllBuyService();
      if (result.succeeded) {
        if (result.data.length > 0) {
          result.data.forEach((value, index) => {
            this.buyServiceList.push({
              id: value.id,
              number: value.number,
              amount: value.amount,
              createDate: value.createDate,
              monthCount: value.monthCount,
              farmCount: value.farmCount,
              area: value.area,
              costService: value.costService,
              paymentState: value.paymentState,
              paymentType: value.paymentType,
              facktorType: value.facktorType,
              paymentStateNumber: value.paymentStateNumber,
            });
          });
        } else {
          this.emptyTitle = true;
        }
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: `${result.message} <br /><br />`,
          type: "error",
        });
      }
      this.loading = false;
    },
    async getBuyServiceDetail(buyServiceId) {
      this.$emit("setBuyService", buyServiceId, "detail");
    },
    async getAllFarmWithBuyService(buyServiceId) {
      this.$emit("setBuyService", buyServiceId, "farmList");
    },
    async deletePerfixInfo(buyServiceId) {
      this.$confirm({
        message: `پیش فاکتور مورد نظر حذف شود؟`,
        button: {
          no: "لغو",
          yes: "بله",
        },
        callback: async (confirm) => {
          if (confirm) {
            this.loading = true;
            this.loadingText = "در حال حذف پیش فاکتور...";
            let result = await this.DeletePrefixInfo({
              id: buyServiceId,
            });
            this.loading = false;
            if (result.data) {
              if (result.succeeded) {
                await this.getAllBuyServiceListInfo();
                this.$notify({
                  title: "پیام سیستم",
                  text: "پیش فاکتور مورد نظر حذف شد <br /><br />",
                  type: "success",
                });
              } else {
                this.$notify({
                  title: "پیام سیستم",
                  text: "امکان حذف این پیش فاکتور وجود ندارد <br /><br />",
                  type: "error",
                });
              }
            } else {
              this.$notify({
                title: "پیام سیستم",
                text: `خطای سیستمی رخ داده با پشتیبانی تماس بگیرید <br /><br />`,
                type: "error",
              });
            }
          }
        },
      });
    },
  },
  mounted() {
    this.getAllBuyServiceListInfo();
  },
};
</script>

<style scoped>
.factorList {
  width: 100%;
}

.factorItemInfo {
  width: 100%;
}

.factorItemInfo h4 {
  font-family: "IRANSansWeb", serif;
  font-size: 0.8rem;
  height: 2.2vh;
  color: #1f1f1f;
  font-weight: bold;
}

.factorItemInfo h5 {
  text-align: right;
  font-size: 14px;
}

.emptyTitleStyle {
  width: 100%;
  height: 15vh;
  text-align: center;
  padding-top: 4%;
  color: #979797;
  font-family: yekan, serif;
}
</style>
