<script>
export default {
  name: "factorCard"
}
</script>

<template>
  <div class="factor">
    <div class="factor__content">
      <div class="vertical-g-10">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<style scoped>

.factor {
  position: relative;
  box-sizing: border-box;
  padding: 20px;
  border-radius: 10px;
  background: #fbfbfb;
  box-shadow: 2px 2px 15px 0 #aaa;
}

.factor:before, .factor:after {
  content: "";
  position: absolute;
  left: 5px;
  height: 6px;
  right: 5px;
}

.factor:before {
  top: -5px;
  background: radial-gradient(circle, transparent, transparent 50%, #fbfbfb 50%, #fbfbfb 100%) -7px -8px/14px 14px repeat-x;
}

.factor:after {
  bottom: -5px;
  background: radial-gradient(circle, transparent, transparent 50%, #fbfbfb 50%, #fbfbfb 100%) -7px -2px/14px 14px repeat-x;
}

.factor__content {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  border: 6px solid #eee;
  padding: 10px;
}
</style>
