<template>
  <CRow>
    <div class="light-1"></div>
    <div class="light-2"></div>
    <vue-element-loading
      :active="loading"
      :text="loadingText"
      spinner="bar-fade-scale"
      color="var(--secondary)"
    />
    <CCol md="12">
      <facktorList @setBuyService="callSubForm"/>
    </CCol>
    <VueModal
      v-model="modelShowState"
      :title="modalTitle"
      wrapper-class="animate__animated animate__faster"
      in-class="animate__fadeIn"
      out-class="animate__fadeOut"
      bg-class="animate__animated"
      modalStyle="max-width:50vw !important;width:50vw !important"
    >
      <component v-if="subForm != null" v-bind:is="subForm" :buyServiceItem="buyServiceDetail" :farmList="farmListModel" />
    </VueModal>
  </CRow>
</template>

<script>
import facktorList from "./facktorList.vue";
import facktorDetail from "./facktorDetail.vue";
import farmListItem from "./farmListItem.vue";
import {mapActions} from "vuex";

export default {
  components: {
    facktorList,
    facktorDetail,
    farmListItem
  },
  data() {
    return {
      loading: false,
      loadingText: "",
      modalTitle: "لیست زمین های مربوط به فاکتور",
      modelShowState: false,
      subForm: null,
      buyServiceDetail: {
        id: null,
        number: null,
        state: null,
        paymentType: null,
        transactionNumber: null,
        currentInventory: null,
        beforInventory: null,
        amount: null,
        offerCode: null,
        offerFestival: null,
        userServiceCost: null,
        areaCount: null,
        famrCount: null,
        monthCount: null,
        yearAndMonthList: []
      },
      listOfDateTime: [],
      farmListModel: []
    };
  },
  computed: {},
  methods: {
    ...mapActions("financialUser", [
      "ReportGetBuyServiceDetail",
      "ShopReportGetFarmInfo"
    ]),

    async callSubForm(buyServiceId, formName) {
      if (formName === "detail") {
        await this.getBuyServiceDetail(buyServiceId);
          this.loadSubFormFarm('facktorDetail', 'جزئیات فاکتور');
      } else if (formName === "farmList") {
        await this.getAllFarmWithBuyService(buyServiceId);
        this.loadSubFormFarm('farmListItem', 'نمایش زمین');
      } else {
        return null;
      }
    },
    async getBuyServiceDetail(buyServiceId) {
      this.loading = true;
      this.loadingText = "دریافت جزئیات فاکتور ...";
      let result = await this.ReportGetBuyServiceDetail({
        buyServiceId: buyServiceId
      });
      this.loading = false;
      if (result.succeeded) {
        this.buyServiceDetail.id = result.data.id;
        this.buyServiceDetail.number = result.data.number;
        this.buyServiceDetail.state = result.data.state;
        this.buyServiceDetail.paymentType = result.data.paymentType;
        this.buyServiceDetail.transactionNumber = result.data.transactionNumber;
        this.buyServiceDetail.currentInventory = result.data.currentInventory;
        this.buyServiceDetail.beforInventory = result.data.beforInventory;
        this.buyServiceDetail.amount = result.data.amount;
        this.buyServiceDetail.offerCode = result.data.offerCode;
        this.buyServiceDetail.offerFestival = result.data.offerFestival;
        this.buyServiceDetail.userServiceCost = result.data.userServiceCost;
        this.buyServiceDetail.areaCount = result.data.areaCount;
        this.buyServiceDetail.famrCount = result.data.famrCount;
        this.buyServiceDetail.monthCount = result.data.monthCount;
        this.listOfDateTime = [];
        result.data.buyServiceDetailFarmInfos.forEach((value, index) => {
          this.listOfDateTime.push({
            farmName: value.farmName,
            month: value.month,
            year: value.year
          });
        });
        this.buyServiceDetail.yearAndMonthList = this.listOfDateTime;
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: `${result.message} <br /><br />`,
          type: "error"
        });
      }
      this.loading = false;
    },
    loadSubFormFarm(subFormName, subFormTitle) {
      this.subForm = subFormName;
      this.modalTitle = subFormTitle;
      this.modelShowState = true;
    },
    async getAllFarmWithBuyService(buyServiceId) {
      this.loading = true;
      this.loadingText = "دریافت لیست زمین های فاکتور";
      let result = await this.ShopReportGetFarmInfo({
        buyServiceId: buyServiceId
      });
      if (result.succeeded) {
        this.farmListModel = [];
        result.data.forEach((value, index) => {
          this.farmListModel.push({
            title: value.title,
            address: value.address,
            cultivationsType: value.cultivationsType,
            product: value.product
          });
        });
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: `${result.message} <br /><br />`,
          type: "error"
        });
      }
      this.loading = false;
    }
  }
};

</script>
