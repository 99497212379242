<template>
  <CRow class="factor-wrapper gap-20 justify-content-evenly">
    <CCol col="6" class="farmDateInfo my-4">
      <h6>ماه های خریداری شده</h6>
      <hr class="mt-2 mb-3"/>
        <div
          class="main-card farm-item mb-3"
          v-for="(item,farmName) in groupedByFarm"
          :key="farmName"
        >
          <span class="">نام زمین: {{ farmName }}</span>
          <ul class="farm-list">
            <li v-for="(date,index) in item" :key="index">
               <span class="font-weight-bold">{{ getMonthName(date.month)}}</span> ماه سال <span class="font-weight-bold">{{ date.year }}</span>
            </li>
          </ul>
        </div>
    </CCol>
    <CCol col="auto">
      <factor-card>
        <div class="infoBoxItemTitle">
          <span>شماره فاکتور :</span>
          <span>{{ buyServiceDetail.number }}</span>
        </div>
        <hr/>
        <div class="itemRow">
          <h6>وضعیت فاکتور</h6>
          <h6>{{ buyServiceDetail.state }}</h6>
        </div>
        <div class="itemRow">
          <h6>نوع پرداخت</h6>
          <h6>{{ buyServiceDetail.paymentType }}</h6>
        </div>
        <div class="itemRow">
          <h6>شماره تراکنش</h6>
          <h6>{{ buyServiceDetail.transactionNumber }}</h6>
        </div>
        <div class="itemRow">
          <h6>موجودی فعلی</h6>
          <h6>{{ buyServiceDetail.currentInventory }}</h6>
        </div>
        <div class="itemRow">
          <h6>موجودی قبلی</h6>
          <h6>{{ buyServiceDetail.beforInventory }}</h6>
        </div>
        <div class="itemRow">
          <h6>کد تخفیف</h6>
          <h6>{{ buyServiceDetail.offerCode }}</h6>
        </div>
        <div class="itemRow">
          <h6>جشنواره تخفیف</h6>
          <h6>{{ buyServiceDetail.offerFestival }}</h6>
        </div>
        <div class="itemRow">
          <h6>تعداد زمین</h6>
          <h6>{{ buyServiceDetail.famrCount }}</h6>
        </div>
        <div class="itemRow">
          <h6>تعداد ماه</h6>
          <h6>{{ buyServiceDetail.monthCount }}</h6>
        </div>
        <div class="itemRow">
          <h6>هکتار</h6>
          <h6>{{ buyServiceDetail.areaCount }}</h6>
        </div>
        <div class="itemRow">
          <h6>هزینه خدمات</h6>
          <h6>{{ buyServiceDetail.userServiceCost }} ریال</h6>
        </div>
        <hr/>
        <div class="itemRow">
          <h6>مبلغ</h6>
          <h6>{{ buyServiceDetail.amount.toLocaleString() }} ریال</h6>
        </div>
      </factor-card>
    </CCol>
  </CRow>
</template>

<script>
import storeMonths from "../../../store/data/month.json";
import FactorCard from "../../../components/factorCard.vue";

export default {
  props: {
    buyServiceItem: {
      type: Object,
    },
  },
  components: {FactorCard},
  data() {
    return {
      buyServiceDetail: this.buyServiceItem,
      allMonth: storeMonths,
      groupedByFarm:[]
    };
  },
  computed: {},
  methods: {
    getMonthName(numberMonth) {
      let m = this.allMonth.find((x) => x.value == numberMonth);
      return m.label;
    },
  },
  mounted() {
    this.groupedByFarm = this.buyServiceDetail.yearAndMonthList.reduce(
      (result, item) => {
        const { farmName } = item;
        if (!result[farmName]) {
          result[farmName] = [];
        }
        result[farmName].push(item);
        return result;
      },
      {}
    );
  },
  created() {
  }
};
</script>
<style scoped>
.factor-wrapper{
  margin: 40px;
}
.itemRow{
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
}

.farm-list{
  list-style: decimal;
  margin: 5px 0;
}
.farm-item{
  background: var(--secondary);
  color: #fff;
}
</style>
